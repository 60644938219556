import type { FeatureInfo } from '@sparky/framework'

export function isFeatureEnabled(featureInfo: FeatureInfo[] | undefined, featureKey: string) {
	return !!featureInfo?.some(f => f.name === featureKey && f.toggle === 1)
}
export function getEpmConfigRootKey() {
	return 'workflows'
}

export function isEpmConfigAvail() {
	return true
}
